<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-card elevation="6" outlined>

      <v-card-text class="ma-0 pa-0">


        <v-card elevation="0">

          <v-card-title class="pa-0 ma-0">
            <v-row>

              <v-col class="pt-2 ma-0" cols="12">
                <div class="card-header-tab card-header">
                  <div class="card-header-title font-size-lg  pt-8 pr-2 text-capitalize font-weight-normal">
                    <v-row class="">
                      <v-col cols="12" md="4" sm="12">
                        <b-input-group width="100%">
                          <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                        :placeholder="$t('datatables.lang_search')"
                                        @focus="showTouchKeyboard"
                                        dense
                                        outlined
                                        prepend-inner-icon="search"
                                        v-model="search"/>
                        </b-input-group>
                      </v-col>
                      <v-col cols="12" md="4" sm="12">
                        <b-input-group width="100%">
                          <birthdaypicker dense v-bind:current-date="this.start"
                                          v-model="start"/>

                          <!--<date-picker confirm
                                       format="[on] YYYY-DD-MM [at] HH:mm" lang="en"
                                       style="width: 100% !important"
                                       type="datetime" v-model="start"
                                       value-type="timestamp"></date-picker>-->
                        </b-input-group>
                      </v-col>
                      <v-col cols="12" md="4" sm="12">
                        <b-input-group width="100%">
                          <birthdaypicker dense
                                          v-bind:current-date="this.end"
                                          v-bind:min="this.start"
                                          v-model="end"/>

                          <!--<date-picker format="[on] YYYY-DD-MM [at] HH:mm"
                                       lang="en" style="width: 100% !important"
                                       type="datetime"
                                       v-model="end" value-type="timestamp"></date-picker>-->
                        </b-input-group>
                      </v-col>
                    </v-row>
                  </div>

                  <!--                  <div class="btn-actions-pane-right actions-icon-btn">
                                      <b-dropdown no-caret right toggle-class="btn-icon btn-icon-only" variant="link">
                                                      <span slot="button-content"><i class="pe-7s-menu btn-icon-wrapper"></i>
                                                      </span>
                                        <div>
                                          <h6 class="dropdown-header" tabindex="-1">
                                            {{ $t('generic.lang_Options') }}</h6>
                                          <button @click="generatePdfAll" class="dropdown-item" tabindex="0"
                                                  type="button"
                                          >
                                            <v-icon class="dropdown-icon" color="warning">picture_as_pdf
                                            </v-icon>
                                            <span>PDF</span>
                                          </button>

                                          <button class="dropdown-item"
                                                  tabindex="0" type="button">
                                            <font-awesome-icon class="dropdown-icon success&#45;&#45;text"
                                                               icon="file-excel"/>
                                            <span>Excel</span></button>
                                        </div>
                                      </b-dropdown>
                                    </div>-->
                </div>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider class="ma-0 pa-0"/>
          <v-data-table
              :footer-props="footerProps"
              :headers="datatableHeaders"
              :items="dataToShow"
              :loading="loading"
              :options.sync="pagination"
              :server-items-length="total"
              :show-select="true"
              footer-props.items-per-page-text="Einträge pro Seite"
              item-key="userID"
              v-model="selectedRows"
          >

            <template v-slot:item.userID="{ item }">

                            <span :style="{
                                color: getRandomColor()
                            }" class="text-muted text-center">#{{ item.userID }}</span>

            </template>


            <template v-slot:item.overtime="{ item }">

              <v-chip

                  :class="getColor(item)"
                  dot
              >
                {{ secondsToTime(item.overtime).h + 'h:' + secondsToTime(item.overtime).m + 'm' }}
              </v-chip>

            </template>


            <template v-slot:item.name="{ item }">

              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left mr-3">
                    <v-avatar class="widget-content-left primary" size="40">
                      <img :src="item.avatarURI" alt="" class="rounded-circle"
                      >
                    </v-avatar>
                  </div>
                  <div class="widget-content-left flex2">
                    <div class="widget-heading">{{ item.firstName + " " + item.lastName }}</div>
                    <div class="widget-subheading opacity-7">{{ item.R }}</div>
                  </div>
                </div>
              </div>

            </template>

            <template v-slot:item.totalCash="{value}">
              {{ parseFloat(value).toFixed(2) | currency }}
            </template>

            <template v-slot:item.totalNonCash="{value}">
              {{ parseFloat(value).toFixed(2) | currency }}
            </template>

            <template v-slot:item.total="{value}">
              {{ parseFloat(value).toFixed(2) | currency }}
            </template>

            <template v-slot:item.action="{ item }">
              <v-btn :disabled="loadingPDF" @click="showPrintersDialog(item)" class="ma-1 btn-sm" color="warning" icon
                     type="button">
                <v-icon size="23">picture_as_pdf</v-icon>
              </v-btn>
              <!--              <v-btn @click="downloadExcel(item)" class="ma-1 btn-sm" color="success" icon
                                   type="button">
                              <font-awesome-icon class="dropdown-icon" icon="file-excel"
                                                 size="lg"/>
                            </v-btn>-->

            </template>

          </v-data-table>
        </v-card>


        <div id="onScreenKeyboardDiv">
          <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                              :defaultKeySet="touchKeyboard.keySet"
                              :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                              :options="touchKeyboard.options" class="internalWidthExpanded"
                              id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
        </div>


      </v-card-text>
    </v-card>

    <!-- start of printers picker dialog -->
    <print-waiter-sales-component v-if="this.printers_dialog" v-model="printers_dialog" @closed="printersDialogClosed"
                                  :user="this.selected_user"
                                  :start_date="this.start"
                                  :end_date="this.end"/>
    <!-- end of printers picker dialog -->

  </v-container>
</template>


<script>
import {library} from '@fortawesome/fontawesome-svg-core';
import {faFileExcel, faInfo, faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import FileSaver from 'file-saver';
import {faBarcodeRead} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {Events} from "../../../plugins/events";
import {ENDPOINTS} from "../../../config";
import {mapState} from "vuex";
import mixin from "@/mixins/KeyboardMixIns";

import VueHtml2pdf from 'vue-html2pdf'
import Birthdaypicker from "@/components/common/birthdaypicker";
import DatePicker from 'vue2-datepicker'
import HistoryTimeLine from "../../timesheet/analyses/HistoryTimeLine";


import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor';
import Template from "../../settings/customerdisplay/Template";
import PrintWaiterSalesComponent from "./PrintWaiterSalesComponent";

library.add(
    faFileExcel, faBarcodeRead, faPaperPlane, faInfo
);

export default {
  name: "WaiterTipsComponent",

  components: {
    PrintWaiterSalesComponent,
    Template,
    HistoryTimeLine,
    Birthdaypicker,
    DatePicker,
    'font-awesome-icon': FontAwesomeIcon,
    'vue-html2pdf': VueHtml2pdf,
    quillEditor
  },

  mixins: [mixin],

  data() {
    return {
      loadingPDF: false,
      quillOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'align': []}],
            [{'font': []}],
          ],
        },
      },
      form: {
        email: "",
        subject: "",
        message: "",
        excel: false,
        pdf: false,
        userId: ''
      },
      searchEmail: "",
      sendLoader: false,
      times: null,
      details: false,
      start: "",
      end: "",
      ENDPOINTS,
      excelFileName: "Trinkgelder",
      dataToShow: [],
      search: '',
      awaitingSearch: null,
      total: 0,
      loading: false,
      selectedRows: [],
      pagination: {},
      footerProps: {'items-per-page-options': [15, 30, 50, 100]},
      rowsPerPageItems: [10, 20, 50, 100],
      avtivateAll: false,
      disavtivateAll: false,
      hover: false,
      fab: false,
      loadingDialog: false,
      user: null,
      pauseTimeJSON: null,
      selected_user: null,
      printers_dialog: false
    }
  },

  computed: {
    ...mapState([
      'touchkeyboard'
    ]),
    excelColumns: function () {
      return [
        {
          label: "ID",
          field: "id",
        },
        {
          label: this.$t('customers.lang_cust_name'),
          field: "name",
        },
        {
          label: this.$t('erp.lang_ware_brut'),
          field: "total",
        }
      ];
    },
    datatableHeaders: function () {
      return [
        {
          text: "#" + this.$t('settings.lang_userid'),
          value: "userID",
          sortable: true,
        },
        {
          text: this.$t('customers.lang_cust_name'),
          value: "name",
          sortable: false
        },
        {
          text: this.$t('generic.lang_tipCash'),
          value: "totalCash",
          align: "center",
        },
        {
          text: this.$t('generic.lang_nonCashTip'),
          value: "totalNonCash",
          align: "center",
        },
        {
          text: this.$t('generic.lang_tipsTotal'),
          value: "total",
          align: "center",
        }
      ];
    },
  },
  mounted() {
    let today = new Date();
    today.setDate(today.getDate());

    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.start = this.dateFormater(today);
    this.end = this.dateFormater(tomorrow);
    //console.log(this.user)
  },

  watch: {

    search: function () {
      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        if (this.pagination.page !== 1) {
          this.pagination.page = 1;
        } else {
          this.getDataFromApi();
        }
      }, 500);
    },
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    start(val) {
      if (this.end && (this.dateToUnix(new Date(val)) >= this.dateToUnix(new Date(this.end))))
        this.end = this.dateFormater(this.lastDay(new Date(this.start)))
      if (this.start && this.end)
        this.getDataFromApi();
    },
    end(val) {
      if (this.start && this.end)
        val && this.getDataFromApi();
    }
  },

  methods: {
    printersDialogClosed() {
      this.selected_user = null;
    },
    showPrintersDialog(user) {
      this.selected_user = {...user}
      this.printers_dialog = true;
    },
    filter(item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
    },
    generatePdfAll() {

    },
    secondsToTime(secs) { //this function convert incoming times from DB as seconds to human time
      var hours = Math.floor(secs / (60 * 60));

      var divisor_for_minutes = secs % (60 * 60);
      var minutes = Math.floor(divisor_for_minutes / 60);
      var divisor_for_seconds = divisor_for_minutes % 60;
      var seconds = Math.ceil(divisor_for_seconds);

      var obj = {
        "h": hours < 10 ? '0' + hours : hours,
        "m": minutes < 10 ? '0' + minutes : minutes,
        "s": seconds < 10 ? '0' + seconds : seconds
      };
      return obj;
    },
    timeToSeconds(time) {
      let arr = time.split(':');

      if (arr.length == 3)
        return (parseInt(arr[0]) * 3600) + (parseInt(arr[1]) * 60) + parseInt(arr[2]);
      else if (arr.length == 2)
        return (parseInt(arr[0]) * 3600) + (parseInt(arr[1]) * 60);
      else if (arr.length == 1)
        return (parseInt(arr[0]) * 3600);
    },
    getColor(item) {

      let the10 = (15 * this.timeToSeconds(item.worktimeNet)) / 100,
          the20 = (15 * this.timeToSeconds(item.worktimeNet)) / 100;

      if (parseInt(item.overtime) <= the10)
        return 'success';
      else if (parseInt(item.overtime) > the10 && parseInt(item.overtime) <= the20)
        return "wrong"
      else return "error";
    },
    dateFormater(date) {
      let day = parseInt(date.getDate()) < 10 ? '0' + date.getDate() : date.getDate(),
          month = (parseInt(date.getMonth()) + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1),
          year = parseInt(date.getFullYear());
      return year + '-' + month + '-' + day
    },
    dateToUnix(date) {
      return Math.round(+date.getTime() / 1000);
    },
    downloadExcel: function (item) {
      let url = this.axios.defaults.baseURL + "/" + ENDPOINTS.TIMESHEET.ANALITICS.EXCELSINGEL + "?start_date=" + this.dateToUnix(new Date(this.start)) + "&end_date=" + this.dateToUnix(new Date(this.end)) + "&userID=" + item.userID;

      url += "&apiToken=" + this.axios.defaults.headers.common['3POSAPITOKEN'] + "&bs=" + this.axios.defaults.headers.common['3POSBS'];
      url += "&bsid=" + this.axios.defaults.headers.common['3POSBSID'];

      let a = document.createElement('a');
      a.setAttribute('target', '_blank');
      a.href = url;
      a.click();
      a.remove();
    },
    downloadPDF: function (item) {
      /*      let url = this.axios.defaults.baseURL + "/" + ENDPOINTS.TIMESHEET.ANALITICS.PDFSINGL + "?reportStart=" + this.dateToUnix(new Date(this.start)) + "&reportEnd=" + this.dateToUnix(new Date(this.end)) + "&userId=" + item.userID;

            url += "&apiToken=" + this.axios.defaults.headers.common['3POSAPITOKEN'] + "&bs=" + this.axios.defaults.headers.common['3POSBS'];
            url += "&bsid=" + this.axios.defaults.headers.common['3POSBSID'];

            let a = document.createElement('a');
            a.setAttribute('target', '_blank');
            a.href = url;
            a.click();
            a.remove();*/

      this.loadingPDF = true;
      this.axios.post(ENDPOINTS.TIMESHEET.ANALITICS.PDFSINGL, {
            reportStart: this.dateToUnix(new Date(this.start)),
            reportEnd: this.dateToUnix(new Date(this.end)),
            userId: item.userID
          },
          {
            responseType: 'arraybuffer',
          }).then((res) => {
        //GENERATE PDF CONTENT FOR iFRAME
        const blobContent = new Blob([res.data], {type: "application/pdf"});
        /*        let a = document.createElement('a');
                a.setAttribute('target', '_blank');
                a.href = url;
                a.click();
                a.remove();
                this.iframePDFContent = URL.createObjectURL(blobContent);
                this.displayInvoiceDialog = true;*/
        //DOWNLOAD INVOICE AS PDF
        FileSaver.saveAs(new Blob([res.data], {
          type: "application/pdf"
        }), item.userID + ".pdf");
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingPDF = false;
      })
    },
    lastDay(date) {
      let dt = new Date(date.getFullYear(), (date.getMonth() + 1), 0);
      return dt;
    },
    getRandomColor() {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    resetSelectedRows() {
      this.selectedRows = [];
    },
    getDataFromApi() {
      this.loading = true;
      this.selectedRows = [];

      let params = {
        sEcho: 1,
        iDisplayStart: this.pagination.itemsPerPage * (this.pagination.page - 1),
        iDisplayLength: this.pagination.itemsPerPage
      };

      if (this.start && this.end) {
        params.start_date = Math.round(+new Date(this.start).getTime() / 1000);
        params.end_date = Math.round(+new Date(this.end).getTime() / 1000);
      }

      //SORTING / PAGINATION / SEARCHING
      if (this.search) {
        params.sSearch = this.search;
      }

      if (this.pagination.sortBy) {
        //FIND COL. INDEX
        let index = this.datatableHeaders.findIndex(header => header.value === this.pagination.sortBy[0]);

        if (index < 0) {
          index = 0;
        }

        params["iSortingCols"] = 1;
        params["iSortCol_0"] = index;
        params["bSortable_" + index] = "true";
        params["sSortDir_0"] = this.pagination.sortDesc[0] ? 'desc' : 'asc';
      }


      this.axios.get(ENDPOINTS.DATATABLES.ACCOUNTING.WAITERTIPS, {
        params: params
      }).then((res) => {
        //console.log(res)
        this.total = parseInt(res.data.iTotalDisplayRecords);
        this.dataToShow = res.data.aaData;

        this.loading = false;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });

        this.dataToShow = [];
      });
    }
  }
}
</script>

<style scoped>
.card-header.card-header-tab .card-header-title {
  width: 100%;
}

#create .v-speed-dial {
  position: fixed !important;
  right: 0;
  bottom: 0;
  border: 2px solid red !important;
}

#create .v-btn--floating {
  position: relative;
}

.rouded-btn {
  position: fixed !important;
  z-index: 1000;
  bottom: 0;
  right: 0;
}

input {
  background-color: white !important;
}

v-dialog:not(.v-dialog--fullscreen) {
  box-shadow: none !important;
}

</style>
